import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

const priorityMap = {
  code: 1,
  book: 2,
};

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    const reOrganized = posts.reduce((acc, cur) => {
      const tag = cur.node.frontmatter.tags.find(tag => tag === 'code' || tag === 'book');
      if (!acc[tag]) acc[tag] = [cur];
      else acc[tag] = [...acc[tag], cur];
      return acc;
    }, {});
    const keys = Object.keys(reOrganized);
    keys.sort((a, b) => priorityMap[a] - priorityMap[b]);

    return (
      <div className="columns is-multiline">
        <ul style={{ listStyleType: 'none' }}>
          {Object.keys(reOrganized).length 
            ? keys.map((category, i) => (
              <div
                style={{ marginTop: i === 0 ? '0' : '1rem'}}
              >
                <h4>{_getDisplay(category)}</h4>
                {reOrganized[category].map(({ node: post }) => (
                  <li>
                    <Link to={post.fields.slug}>
                      {`${post.frontmatter.date} - ${post.frontmatter.title}`}
                    </Link>
                  </li>
                ))}
              </div>
            ))
            : null
          }
        </ul>
      </div>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                tags
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)

function _getDisplay(title) {
  const valueMap = { code: 'Code', book: 'Books' };
  return valueMap[title];
}