import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                  Welcome to Tiny Blog
                </h2>
                <h3 className="title is-size-5 has-text-weight-bold is-bold-light">
                  A space for tiny thoughts
                </h3>
                <div className="container">
                  <div className="content">
                    <BlogRoll />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
